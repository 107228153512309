import React, { Fragment } from 'react';
import { Modal, Button, Col, Row, Tab, Nav, NavItem } from 'react-bootstrap';
import { Loader, ErrorBox, ContentBox, Callout, UserProfilePicture, UserDetailsLink } from '../../../../common/uiElements';
import componentRequestHandler from '../../../../../app/api/helpers/componentRequestHandler';
import { IError, IReactSelectOption, IReactSelectReturn } from '../../../../../app/models/Application';
import PalletApi from '../../../../../app/api/PalletApi';
import { IPallet_Unload, IPalletUnloadDetails, PalletUnload_POST_PUT, PalletUnloadShuttleDetailForm, PalletUnloadDetailsForm as PalletUnloadDetailsFormClass, UnloadedByForm } from '../../../../../app/models/Pallet';
import moment from 'moment';
import DurationParse from '../../../../../app/helpers/DurationParse';
import $ from'jquery';
import FontAwesome from 'react-fontawesome';
import confirm from '../../../../../app/helpers/confirm';
import PalletUnloadDetailsForm  from './PalletUnloadDetailsForm';
import PalletsListUnloads from './PalletsListUnloads';
import PalletShuttledDetailsModal from './PalletShuttledDetailsModal';

interface IProps {
	unloadId?: string;
	isAddingNewTask?: boolean;
	onClose: () => void;
	onCompleteSaving?: (unloadId?: string) => void;
	onCompleteDeletion?: (unloadId?: string) => void;
}

interface IState {
	details: PalletUnloadDetailsFormClass;
	isReadOnly: boolean;
	selectedPallet?: IPallet_Unload;

	isSaving: boolean;
	isLoading: boolean;

	errorSaving?: IError;
	errorUnloading?: IError;
	errorDeleting?: IError;
}

class PalletUnloadDetailsModal extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = { 
			details: new PalletUnloadDetailsFormClass(),
			isReadOnly: false,
			selectedPallet: undefined,

			isLoading: false,
			isSaving: false,

			errorUnloading: undefined,
			errorSaving: undefined,
			errorDeleting: undefined
		}
	}

	componentDidUpdate(prevProps: IProps) {
		if (this.props.unloadId !== prevProps.unloadId)
			this.fetchDetails();
	}	

	componentDidMount() {
		if(!this.props.isAddingNewTask) 
			this.fetchDetails() 
		else 
			this.setState({  details: { 
				...this.state.details,
				fleetNumberManuallyEntered: false,
				startedAt: new Date().toString(),
				finishedAt: new Date(new Date().getTime() + 1000).toString(),
				deviceName: 'Manual Entry',
			}});
	}

	fetchDetails = () => {
		if (!this.props.unloadId)
			return;

		this.setState({ errorUnloading: undefined, isLoading: true });
		PalletApi.unloads.getUnloadDetails(this.props.unloadId)
			.then((details: IPalletUnloadDetails) => {
				this.setState({ 
					isReadOnly: !!details.cancelledAt,
					details: new PalletUnloadDetailsFormClass(details)
				})
			})
			.catch(errorUnloading => this.setState({ errorUnloading }))
			.finally(() => this.setState({ isLoading: false }))
	}

	saveUnload = () => {
		const { unloadId } = this.props;
		const dataToSend = new PalletUnload_POST_PUT(this.state.details);
		let promise = () => PalletApi.unloads.createUnload(dataToSend);

		if (unloadId)
			promise = () => PalletApi.unloads.updateUnload(unloadId, dataToSend);

		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isSaving',
			errorAttrName: 'errorSaving',
			showSuccessNotification: true
		})
		.then(() => {
			this.fetchDetails();
			this.props.onCompleteSaving && this.props.onCompleteSaving(unloadId);
		})
	}

	deleteUnload = () => {
		const { unloadId: loadId, onCompleteDeletion } = this.props;
		if (!loadId) return;

		confirm('Are you sure you want to delete this load? This action cannot be undone.',
			() => {
				const promise = PalletApi.unloads.deleteUnload.bind(this, loadId);
				componentRequestHandler(this, promise, undefined, {
					loadingAttrName: 'isSaving',
					errorAttrName: 'errorSaving',
					showSuccessNotification: true
				})
				.then(onCompleteDeletion)
			}
		)
	}

	changeForm = (newData: object) => {
		this.setState({
			details: {
				...this.state.details,
				...newData
			}
		})
	}
	
	handleChangeForm = (id: string, value: any) => {
		this.changeForm({ [id]: value })
	}

	handleChangeDepot = (depot?: IReactSelectReturn) => {
		this.changeForm({ depot: { id: depot?.value || '', name: depot?.label || '' } });
	}

	handleChangeUnloadedByOld = (index: number, loader?: IReactSelectOption) => {
		const { details } = this.state;
		if (!details.unloadedBy) return;

		const unloadedBy = details.unloadedBy.find((_, i) => i === index);
		if (!unloadedBy) return;
		unloadedBy.id = loader?.value || '';
		unloadedBy.name = loader?.label || '';
		
		this.setState({
			details: {
				...details,
				unloadedBy: details.unloadedBy
			}
		})
	}

	handleChangeUnloadedBy = (index: number, unloadedBy: UnloadedByForm) => {
		const { details } = this.state;

		details.unloadedBy[index] = { ...unloadedBy };

		this.setState({
			details: {
				...details,
				unloadedBy: [ ...details.unloadedBy ]
			}
		})
	}

	handleChangeUnloadPercentage = (index: number, value: string) => {
		this.changeForm({ 
			unloadedBy: this.state.details.unloadedBy?.map((loader, i) => i === index ? { ...loader, unloadPercentage: value } : loader) 
		})
	}
	
	handleSelectPallet = (selectedPallet?: IPallet_Unload) => {
		this.setState({ selectedPallet });
	}

	// TODO: Currently this is replacing the pallets to a 'null' array, need to fix this
	handleSavePallet = (pallet: PalletUnloadShuttleDetailForm) => {
		const { details } = this.state;
		if (!details || !details.pallets) return;
		const palletIndex = details.pallets.findIndex(p => p.palletId === pallet.palletId);

		if (palletIndex === -1)
			return;

		details.pallets[palletIndex] = pallet;
		this.setState({ details, selectedPallet: undefined });
	}

	handleAddLoader = () => {
		this.changeForm({ unloadedBy: [...this.state.details.unloadedBy!, new UnloadedByForm()] })
	}

	handleDeleteLoader = (index: number) => {
		this.changeForm({
			unloadedBy: this.state.details.unloadedBy?.filter((_, i) => i !== index)
		})
	}

	handleSave = () => {
		if (!$('#pallet-unload-form').valid())
			return;
		
		this.saveUnload();
	}

	getStatus = () => {
		const { details } = this.state;

		if (!details)
			return {};
		
		if (details.cancelledAt)
			return {
				color: 'danger',
				text: 'CANCELLED'
			}
		else if (details.finishedAt)
			return {
				color: 'success',
				text: 'COMPLETED'
			}
		
		return {
			color: 'primary',
			text: 'IN PROGRESS'
		}
	}

	renderBodyContent = () => {
		const { state, props } = this;
		const status = this.getStatus();

		if (state.errorUnloading)
			return <ErrorBox error={state.errorUnloading} retryFunc={this.fetchDetails} />;

		if (state.isSaving || state.isLoading)
			return <Loader isLoading={state.isLoading} isSaving={state.isSaving} />;


		const { details } = state;
		const loadTimeSpentInSecs = details.finishedAt ? moment.duration(moment(details.finishedAt).diff(details.startedAt)).asSeconds() : undefined;

		return (
			<Row>
				<Col md={4} lg={3}>
					<ContentBox title={details.unloadedBy && details.unloadedBy?.length > 1 ? 'Loaders' : 'Loader'}>
						{
							details.unloadedBy.filter(loader => loader.id).map(loader => (
								<Fragment key={loader.id}>
									<UserProfilePicture className="profile-user-img img-responsive" azureId={loader.id}  />
									<h3 className="profile-username text-center">
										<UserDetailsLink key={loader.id} azureId={loader.id}>
											{ loader.name } ({ loader.unloadPercentage || "0" }%)
										</UserDetailsLink>
									</h3>
								</Fragment>
							))
						}
					</ContentBox>
					<ContentBox color={status.color}>
						<h4 className={`text-${status.color}`}>
							<b>LOAD {status.text}</b>
						</h4>
					</ContentBox>
					<ContentBox>
						<ul className="list list-unstyled">
							<li>
								<b>Empty Pallets</b>
								<span className="pull-right">
									{ 
										parseInt(details.totalEmptyPalletsLoscam || "0")
										+ parseInt(details.totalEmptyPalletsChep || "0")
										+ parseInt(details.totalEmptyPalletsT3 || "0")
									} Pallets
								</span>
							</li>
							<li>
								<b>With Crates</b>
								<span className="pull-right">
									{ 
										parseInt(details.totalPalletsWithCratesChep || "0")
										+ parseInt(details.totalPalletsWithCratesLoscam || "0")
									} Pallets
								</span>
							</li>
							<li>
								<b>Start Time</b>
								<span className="pull-right">
									{ moment(details.startedAt).format('DD/MM/YY HH:mm') }
								</span>
							</li>
							<li>
								<b>Finish Time</b>
								<span className="pull-right">
									{ details.finishedAt ? moment(details.finishedAt).format('DD/MM/YY HH:mm') : <i>In Progress</i> }
								</span>
							</li>
							<li>
								<b>Time Spent</b>
								<span className="pull-right">
									{ loadTimeSpentInSecs ? DurationParse.toDayHourMinSec(loadTimeSpentInSecs, 'short') : <i>In Progress</i> }
								</span>
							</li>
						</ul>
					</ContentBox>
				</Col>
				<Col md={8} lg={9}>
					{ state.errorSaving && <ErrorBox error={state.errorSaving} retryFunc={this.saveUnload} /> }
					{ state.errorDeleting && <ErrorBox error={state.errorDeleting} retryFunc={this.deleteUnload} /> }
					<Tab.Container id="palletUnloadTab" className="nav-tabs-custom" defaultActiveKey="details">
						<div>
							<Nav bsStyle="tabs">
								<NavItem eventKey="details">
									<FontAwesome name="info-circle" /> Details
								</NavItem>
							</Nav>
							<Tab.Content animation={false}>
								<Tab.Pane eventKey="details">
									<PalletUnloadDetailsForm 
										{...details}
										isReadOnly={props.unloadId && state.isReadOnly ? true : false}
										onChange={this.handleChangeForm}
										onChangeDepot={this.handleChangeDepot}
										onChangeUnloadedBy={this.handleChangeUnloadedBy}
										onAddLoader={this.handleAddLoader}
										onChangeUnloadPercentage={this.handleChangeUnloadPercentage}
										onDeleteUnloadedBy={this.handleDeleteLoader}
									/>
									{
										details.pallets && details.pallets.length > 0 ? (
											<PalletsListUnloads
												isReadOnly={props.unloadId && state.isReadOnly ? true : false}
												pallets={details.pallets}
												onSelectPallet={this.handleSelectPallet}
												onDeletePallet={() => {}}
											/>
										) : (
											<Callout
												icon="info-circle"
												color='info'
												title="No Scanned Pallets"
											/>
										)
									}
								</Tab.Pane>
							</Tab.Content>
						</div>
					</Tab.Container>
					
				</Col>
			</Row>
		)
	}

	render() {
		const { state, props } = this;

		return (
			<>
				<Modal
					show
					id="pallet-load-modal"
					bsSize="large"
					onHide={props.onClose}
					onEscapeKeyDown={props.onClose}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{ props.unloadId ? 'Unload #' + props.unloadId : 'Add New Unload' }
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{
							state.details.cancelledAt && (
								<Callout 
									icon="info-circle"
									title="Cancelled Unload"
									text="This load has been cancelled and cannot be edited"
								/>
							)
						}
						{ this.renderBodyContent() }
					</Modal.Body>
					{
						!state.isSaving && !state.isLoading && (
							<Modal.Footer>
								{
									!state.isReadOnly && (
										<Button 
											bsStyle="success"
											className="pull-left"
											bsSize="lg"
											onClick={this.handleSave}
										>
											<FontAwesome name="check" /> { props.unloadId ? 'Save Changes' : 'Save Unload' }
										</Button>
									)
								}
								{
									props.unloadId && (
										<Button
											bsStyle="danger"
											onClick={this.deleteUnload}
										>
											<FontAwesome name="trash" /> Delete Unload
										</Button>
									)
								}
								<Button bsSize="lg" onClick={props.onClose}>
									<FontAwesome name="sign-out"/> Close Without Saving
								</Button>
							</Modal.Footer>
						)
					}
				</Modal>
				{/* PALLET DETAILS MODAL  */}
				{
					state.selectedPallet && (
						<PalletShuttledDetailsModal
							pallet={state.selectedPallet}
							isReadOnly={state.isReadOnly}
							onClose={() => this.handleSelectPallet(undefined)}
							onSave={this.handleSavePallet}
						/>
					)
				}
			</>
		)
	}
}

export default PalletUnloadDetailsModal;